import fetch from '../utils/fetch'

// 基础物料
export function getServiceLabelsList (query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'get',
    params: query
  })
}

export function getServiceLabelsDetail (labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'get'
  })
}

export function createServiceLabels (query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'post',
    params: query
  })
}

export function updateServiceLabels (labelId, query) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'put',
    params: query
  })
}

export function deleteServiceLabels (labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'delete'
  })
}

// 商品类型
export function getItemsTypeList (query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'get',
    params: query
  })
}

export function getItemsTypeDetail (typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'get'
  })
}

export function getItemsTypeLabelsDetail (typeId) {
  return fetch({
    url: '/goods/itemstypelabels/' + typeId,
    method: 'get'
  })
}

export function createItemsType (query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'post',
    params: query
  })
}

export function updateItemsType (typeId, query) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'put',
    params: query
  })
}

export function deleteItemsType (typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'delete'
  })
}
// 商品
export function getDistributorList (query) {
  return fetch({
    url: '/distributor/items',
    method: 'get',
    params: {
      ...query,
      distributor_id: !query.distributor_id ? 0 : query.distributor_id
    }
  })
}

// 商品
export function getItemsList (query) {
  return fetch({
    url: '/goods/items',
    method: 'get',
    params: {
      ...query,
      distributor_id: !query.distributor_id ? 0 : query.distributor_id
    }
  })
}

export function getRelItemsList (query) {
  return fetch({
    url: '/goods/getRelItemsList',
    method: 'get',
    params: {
      ...query
    }
  })
}

export function getSkuList (query) {
  return fetch({
    url: '/goods/sku',
    method: 'get',
    params: query
  })
}

export function getItemsDetail (itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'get'
  })
}

export function createItems (query) {
  return fetch({
    url: '/goods/items',
    method: 'post',
    params: query,
    refreshToken: true
  })
}

export function updateItems (itemId, query) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'put',
    params: query,
    refreshToken: true
  })
}

export function setItemsTemplate (params) {
  return fetch({
    url: '/goods/setItemsTemplate',
    method: 'post',
    params: params
  })
}

export function setItemsSort (params) {
  return fetch({
    url: '/goods/setItemsSort',
    method: 'post',
    params: params
  })
}


export function distributorsItem (params) {
  return fetch({
    url: '/distributors/item',
    method: 'put',
    params: params
  })
}

export function setItemsCategory (params) {
  return fetch({
    url: '/goods/setItemsCategory',
    method: 'post',
    params: params
  })
}

export function deleteItems (itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'delete'
  })
}

export function deleteDistributorItems (params) {
  return fetch({
    url: '/distributor/items/',
    method: 'delete',
    params
  })
}
export function getDistributionGoodsWxaCode (query) {
  return fetch({
    url: '/goods/distributionGoodsWxaCodeStream',
    method: 'get',
    params: query
  })
}

export function getItemWarningStore (query) {
  return fetch({
    url: '/goods/warning_store',
    method: 'post',
    params: query
  })
}

export function getCategory (params) {
  return fetch({
    url: '/goods/category',
    method: 'get',
    params
  })
}

export function addCategory (params) {
  return fetch({
    url: '/goods/createcategory',
    method: 'post',
    params: params
  })
}

export function editCategory (params) {
  return fetch({
    url: '/goods/category/' + params.category_id,
    method: 'put',
    params: params
  })
}

export function saveCategory (params) {
  return fetch({
    url: '/goods/category',
    method: 'post',
    params: params
  })
}

export function deleteCategory (category_id) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'delete'
  })
}

export function updateCategory (category_id, query) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'put',
    params: query
  })
}

export function getCategoryInfo (category_id) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'get'
  })
}

export function getGoodsAttr (query) {
  return fetch({
    url: '/goods/attributes',
    method: 'get',
    params: query
  })
}

export function addGoodsAttr (query) {
  return fetch({
    url: '/goods/attributes',
    method: 'post',
    params: query
  })
}

export function updateGoodsAttr (attributes_id, query) {
  return fetch({
    url: '/goods/attributes/' + attributes_id,
    method: 'put',
    params: query
  })
}

export function deleteGoodsAttr (attributes_id) {
  return fetch({
    url: '/goods/attributes/' + attributes_id,
    method: 'delete'
  })
}

export function updateGoodsInfo (query) {
  return fetch({
    url: '/goods/itemsupdate',
    method: 'put',
    params: query
  })
}

export function getGoodsPrice (id) {
  return fetch({
    url: '/goods/memberprice/' + id,
    method: 'get'
  })
}

export function getGoodsProfitPrice (id) {
  return fetch({
    url: '/goods/profit/' + id,
    method: 'get'
  })
}

export function saveGoodsProfitPrice (params) {
  return fetch({
    url: '/goods/profit/save',
    method: 'post',
    params: params
  })
}

export function saveCategoryProfitPrice (params) {
  return fetch({
    url: '/goods/category/profit/save',
    method: 'post',
    params: params
  })
}

export function updateGoodsPrice (query) {
  return fetch({
    url: '/goods/memberprice/save',
    method: 'post',
    params: query
  })
}

export function updateItemRebateConf (query) {
  return fetch({
    url: '/goods/rebateconf',
    method: 'post',
    params: query
  })
}

export function saveTag (query) {
  return fetch({
    url: '/goods/tag',
    method: 'post',
    params: query
  })
}

export function getTagList (query) {
  return fetch({
    url: '/goods/tag',
    method: 'get',
    params: query
  })
}

export function getTagInfo (tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'get'
  })
}

export function updateTag (query) {
  return fetch({
    url: '/goods/tag',
    method: 'put',
    params: query
  })
}

export function deleteTag (tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'delete'
  })
}

export function itemsRelTags (query) {
  return fetch({
    url: '/goods/reltag',
    method: 'post',
    params: query
  })
}

export function tagSearchItem (query) {
  return fetch({
    url: '/goods/tagsearch',
    method: 'GET',
    params: query
  })
}

export function setHotKeywords (query) {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'post',
    params: query
  })
}

export function getHotKeywords () {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'get'
  })
}

export function updateItemsStore (query) {
  return fetch({
    url: '/goods/itemstoreupdate',
    method: 'put',
    params: query
  })
}

export function updateItemsStatus (query) {
  return fetch({
    url: '/goods/itemstatusupdate',
    method: 'put',
    params: query
  })
}

export function auditItems (query) {
  return fetch({
    url: '/goods/audit/items',
    method: 'put',
    params: query
  })
}

export function getGoodsExport (query) {
  return fetch({
    url: '/goods/export',
    method: 'get',
    params: query
  })
}

export function exportItemsData (data) {
  return fetch({
    url: '/goods/export',
    method: 'post',
    params: data
  })
}

export function exportItemsTagData (data) {
  return fetch({
    url: '/goods/tag/export',
    method: 'post',
    params: data
  })
}

export function syncItems () {
  return fetch({
    url: '/goods/sync/items',
    method: 'post'
  })
}

export function syncMainCategory () {
  return fetch({
    url: '/goods/sync/itemCategory',
    method: 'post'
  })
}

export function syncItemSpec () {
  return fetch({
    url: '/goods/sync/itemSpec',
    method: 'post'
  })
}

export function syncBrand () {
  return fetch({
    url: '/goods/sync/brand',
    method: 'post'
  })
}

export function saveIsGifts (query) {
  return fetch({
    url: '/goods/itemsisgiftupdate',
    method: 'put',
    params: query
  })
}

// 获取商品分享设置
export function getShareSetting (query) {
  return fetch({
    url: '/itemshare/setting',
    method: 'get',
    params: query
  })
}

// 保存商品分享设置
export function saveShareSetting (query) {
  return fetch({
    url: '/itemshare/setting',
    method: 'post',
    params: query
  })
}

// 导出小程序太阳码/h5二维码
export function exportGoodsCode (params) {
  return fetch({
    url: '/goods/code/export',
    method: 'post',
    params
  })
}

// 选品商品
export function getSelectItemsList (query) {
  return fetch({
    url: '/goods/selectItems',
    method: 'get',
    params: query
  })
}
// 确定选品商品
export function flowItems (query) {
  return fetch({
    url: '/goods/flowItems',
    method: 'post',
    params: query
  })
}


// 创建模板
export function createtpl (query) {
  return fetch({
    url: '/goods/createtpl',
    method: 'post',
    params: query
  })
}

// 模板列表
export function tpllist (query) {
  return fetch({
    url: '/goods/tpllist',
    method: 'get',
    params: query
  })
}
// 删除模板
export function deltpl (id) {
  return fetch({
    url: '/goods/deletetpl/' + id,
    method: 'delete'
  })
}


// 更新模板
export function updatetpl (params) {
  return fetch({
    url: '/goods/updatetpl/',
    method: 'post',
    params
  })
}


// 新增角标
export function createam (query) {
  return fetch({
    url: '/goods/createam',
    method: 'post',
    params: query
  })
}

// 更新角标
export function updateam (query) {
  return fetch({
    url: '/goods/updateam',
    method: 'post',
    params: query
  })
}

// 角标列表
export function amlist (query) {
  return fetch({
    url: '/goods/amlist',
    method: 'get',
    params: query
  })
}

// 角标删除
export function deleteam (id) {
  return fetch({
    url: '/goods/deleteam/' + id,
    method: 'delete',
  })
}

// 管理角标
export function itemsRelAm (query) {
  return fetch({
    url: '/goods/relam',
    method: 'post',
    params: query
  })
}



// 拉黑商品
export function itemstatusupdate (query) {
  return fetch({
    url: '/goods/itemstatusupdate',
    method: 'put',
    params: query
  })
}

// 模板详情
export function goodsTplInfo (id) {
  return fetch({
    url: '/goods/tplinfo/'+ id,
    method: 'get',
  })
}


// 标品库列表
export function crmGetList (params) {
  return fetch({
    url: '/crm/goods/getlist',
    method: 'get',
    params
  })
}

// 标品库列表商品更新
export function crmUpdate (params) {
  return fetch({
    url: '/crm/goods/updateGoods',
    method: 'put',
    params
  })
}

export function crmGetItemsDetail (itemId) {
  return fetch({
    url: '/crm/goods/info/' + itemId,
    method: 'get'
  })
}


export function updateSpecial (query) {
  return fetch({
    url: '/goods/itemsspecialupdate',
    method: 'put',
    params: query
  })
}
export function matchgoodsimages (query) {
  return fetch({
    url: '/goods/matchgoodsimages',
    method: 'get',
    params: query
  })
}
export function distributorsItemEdit (query) {
  return fetch({
    url: '/distributors/item',
    method: 'put',
    params: query
  })
}
export function simpleUpdateItems (query) {
  return fetch({
    url: '/goods/simpleUpdateItems/' + query.item_id,
    method: 'put',
    params: query
  })
}
// 商品列表
export function jmhd8Goods (query) {
  return fetch({
    url: '/jmhd8/goodsList',
    method: 'get',
    params: query
  })
}

// 商品详情
export function jmhd8GoodsDetail (id) {
  return fetch({
    url: '/jmhd8/goodsDetail/' + id,
    method: 'get'
  })
}

// 订单列表
export function jmhd8Order (query) {
  return fetch({
    url: '/jmhd8/order',
    method: 'get',
    params: query
  })
}

// 订单详情
export function jmhd8OrderDetail (id) {
  return fetch({
    url: '/jmhd8/order/detail/' + id,
    method: 'get'
  })
}

// 获取供应链同步日志
export function jmhd8Log (query) {
  return fetch({
    url: '/jmhd8/getLogList',
    method: 'get',
    params: query
  })
}

// 日志重试操作
export function jmhd8LogRe (id) {
  return fetch({
    url: '/jmhd8/retry/' + id,
    method: 'get'
  })
}

// 有售后申请订单
export function jmhd8Handle (id) {
  return fetch({
    url: '/jmhd8/order/doHandle/' + id,
    method: 'get'
  })
}

// 商品推送库存
export function batchSyncDistributorStock (query) {
  return fetch({
    url: '/distributor/batchSyncDistributorStock',
    method: 'post',
    params: query
  })
}

// 处方药药品库
export function goodsDrugItems (query) {
  return fetch({
    url: '/goods/drugItems',
    method: 'get',
    params: query
  })
}

// 开普勒商品列表
export function kplGoods (query) {
  return fetch({
    url: '/jos/sku/getSkuList',
    method: 'get',
    params: query
  })
}

// 开普勒订单推送列表
export function kplOrder (query) {
  return fetch({
    url: '/jos/orderSync/getOrderSyncList',
    method: 'get',
    params: query
  })
}

// 开普勒订单推送
export function kplOrderPush (query) {
  return fetch({
    url: '/jos/orderSync/push',
    method: 'post',
    params: query
  })
}

// 开普勒售后单推送列表
export function kplAftOrder (query) {
  return fetch({
    url: '/jos/afsSync/getAfsSyncList',
    method: 'get',
    params: query
  })
}

// 开普勒售后单推送
export function kplAftPush (query) {
  return fetch({
    url: '/jos/AfsSync/push',
    method: 'post',
    params: query
  })
}

// 开普勒获取去授权url
export function kplAuthUrl () {
  return fetch({
    url: '/jos/oauth/authUrl',
    method: 'get'
  })
}

// 开普勒获取access_token
export function kplAccessToken (query) {
  return fetch({
    url: '/jos/oauth/getAccessToken',
    method: 'post',
    params: query
  })
}

// 库存同步屏蔽配置列表
export function goodsStockSync (query) {
  return fetch({
    url: '/goodsStockSync/setting',
    method: 'get',
    params: query
  })
}

// 库存同步屏蔽
export function addGoodsStockSync (query) {
  return fetch({
    url: '/goodsStockSync/setting',
    method: 'post',
    params: query
  })
}

// 删除库存同步屏蔽
export function delGoodsStockSync (id, query) {
  return fetch({
    url: '/goodsStockSync/setting/' + id,
    method: 'delete',
    params: query
  })
}

export function drugItemsExport () {
  return fetch({
    url: '/goods/drugItemsExport',
    method: 'post'
  })
}
